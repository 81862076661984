import { authService } from "./index.js";
import axios from "axios";

const requestOptions = async (scopes, extraHeaders) => {
    const authHeaders = await authService.getAuthHeaders(scopes);
    return {
        headers: { ...authHeaders, ...extraHeaders },
    };
};

const get = async (path, scopes, extraHeaders) => {
    return await axios.get(
        `${process.env.REACT_APP_ONSIGHT_PLUS_EXT}/v1/${path}`,
        await requestOptions(scopes, extraHeaders)
    );
};

const post = async (path, body, scopes, extraHeaders) => {
    return await axios.post(
        `${process.env.REACT_APP_ONSIGHT_PLUS_EXT}/v1/${path}`,
        body,
        await requestOptions(scopes, extraHeaders)
    );
};

export class UserWebApps {
    /**
     * Gets all relevant app configs for the current user.
     * This should be called immediately after the user has been authenticated
     * in order to determine which configurations (layouts) are applicable to that user.
     * @returns
     */
    static async getUserConfigs(pageId) {
        const response = await get(
            `web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/pages/${pageId}/user-configs`
        );
        return response.data;
    }

    static async getUserPages() {
        const response = await get(
            `web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/user-pages`
        );
        return response.data;
    }
}

export class CallCenter {
    static responderHubUrl = `${process.env.REACT_APP_ONSIGHT_PLUS_EXT}/v1/hubs/cc-responder`;
    static callLaunchHubUrl = `${process.env.REACT_APP_ONSIGHT_PLUS_EXT}/v1/hubs/cc-responder-call`;

    static getRequests(extNameId) {
        return get(`call-center/${extNameId}/requests`);
    }

    static initiateCall(extNameId, requestId, connId) {
        let url = `call-center/${extNameId}/requests/${requestId}/calls`;
        if (connId) {
            url += `?connId=${connId}`;
        }

        return post(url, {});
    }
}
