import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { format } from "date-fns";
import { Button, Modal, Spinner } from "react-bootstrap";
import { HubConnectionBuilder } from '@microsoft/signalr';
import { CallCenter } from "../onsight-plus-ext";
import { CallCenterInstanceName, authService, translate } from "../index";
import { ja } from "date-fns/locale";
import Companies from "../companies";
import { FaBell, FaBellSlash } from "react-icons/fa";

export const Requests = () => {
    const gridRef = useRef();
    const [requests, setRequests] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [showInitializingModal, setShowInitializingModal] = useState(false);
    const showInitializingRef = useRef(showInitializingModal);
    showInitializingRef.current = showInitializingModal;
    const [showTakingTooLong, setShowTakingTooLong] = useState(false);
    const [selectedCustomerName, setSelectedCustomerName] = useState("");
    const [addedCount, setAddedCount] = useState(0);
    const addedCountRef = useRef(addedCount);
    addedCountRef.current = addedCount;
    const [deletedCount, setDeletedCount] = useState(0);
    const deletedCountRef = useRef(deletedCount);
    deletedCountRef.current = deletedCount;
    const [updates, setUpdates] = useState([]);
    const [countUpdate, setCountUpdate] = useState({added: 0, deleted: 0});
    const [audioEnabled, setAudioEnabled] = useState(false);
    const audioEnabledRef = useRef(false);
    const audio = new Audio("bell-123742.mp3");

    const OnsightUserRenderer = (props) => {
        let url = null;
        try {
            url = new URL(props.value);
        } catch (ex) {
            url = props.value;
        }

        return (
            <span>{props.data["ONSIGHT_USER_EMAIL"] === undefined ? props.value : props.data["ONSIGHT_USER_EMAIL"]}</span>
        );
    };

    const WorkspaceLinkRenderer = (props) => {
        let url = null;
        let isUrl = false;
        try {
            url = new URL(props.value);
            isUrl = true;
        } catch (ex) {
            url = props.value;
        }

        return (
            isUrl ? 
                <a href={url} rel="noopener noreferrer" target="_blank">
                    {props.data["TRANSCRIPT_TITLE"] === undefined ? props.value : props.data["TRANSCRIPT_TITLE"]}
                  </a>
                : <span>{props.value}</span>
        );
    };

    const CallButtonRenderer = (props) => {
        if (props.data["STATUS"] === "Canceled") {
            return <></>;
        }

        return (
            <Button 
                className={getCallButtonClass(props.data["STATUS"])} 
                onClick={() => handleCallClick(props.data)}>
                    {translate("CALL")}
            </Button>
        );
    };

    const TranslatedTextRenderer = (props) => {
        return translate(props.value);
    };

    const [columnDefs, setColumnDefs] = useState([
        { field: "CUSTOMER", headerName: translate("CUSTOMER"), flex: 1, minWidth: 70, wrapText: true, autoHeight: true },
        { field: "CUSTOMER_CONTACT", headerName: translate("CUSTOMER_CONTACT"), flex: 1, minWidth: 80, wrapText: true, autoHeight: true },
        { field: "ONSIGHT_USER", headerName: translate("ONSIGHT_USER"), flex: 1.6, minWidth: 180, wrapText: true, autoHeight: true, cellRenderer: OnsightUserRenderer },
        { field: "REQUEST_DATETIME", headerName: translate("REQUEST_DATETIME"), headerClass: "centeredText", cellStyle: { textAlign: "center" }, flex: 1, minWidth: 140, wrapText: true, autoHeight: true,
            comparator: (v1, v2, n1, n2, isDescending) => n1.data.requestDate - n2.data.requestDate },
        { field: "STATUS", headerName: translate("STATUS"), headerClass: "centeredText", cellStyle: { textAlign: "center" }, flex: 1.2, wrapText: true, autoHeight: true, cellRenderer: TranslatedTextRenderer },
        { field: "CUSTOMER_COMMENT", headerName: translate("CUSTOMER_COMMENT"), flex: 1.5, minWidth: 130, wrapText: true, autoHeight: true },
        { field: "TRANSCRIPT", headerName: translate("TRANSCRIPT"), headerClass: "centeredText", cellStyle: { textAlign: "center" }, flex: 0.7, minWidth: 80, wrapText: true, autoHeight: true, cellRenderer: WorkspaceLinkRenderer },
        { field: "RESPONDER", headerName: translate("RESPONDER"), flex: 1.4, minWidth: 130, wrapText: true, autoHeight: true },
        { headerName: "", cellStyle: { textAlign: "center" }, flex: 0.7, minWidth: 50, wrapText: true, autoHeight: true, cellRenderer: CallButtonRenderer },
    ]);

    const defaultColDef = useMemo(
        () => ({
            resizable: true,
            sortable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            flex: 1,
            minWidth: 50,
        }), []
    );

    /**
     * Determines if a requester can be called directly using their Onsight account, 
     * @param {*} req 
     */
    const canCallDirect = req => {
        // Must have requester email address, otherwise we can't find their Onsight account
        if (!req["ONSIGHT_USER_EMAIL"]) {
            return false;
        }

        // Request must already be Done or the requester must have explicitly requested a callback
        return req["STATUS"] === "Done" || req["STATUS"] === "RequiresCallback";
    };

    const handleCallClick = (req) => {
        let url = `call?reqId=${req.requestId}&customerName=${encodeURIComponent(req["CUSTOMER_CONTACT"])}`;
        if (canCallDirect(req)) {
            url += "&direct=true";  // direct call to Onsight account holder (not guest)
        }
        
        window.open(url, "_blank");
    };

    const getCallButtonClass = (status) => {
        let className = "btn-primary";

        switch (status) {
            case "NotStarted":
            case "RequiresCallback":
                className = "btn-danger";
            break;
        }

        return "btn-sm " + className;
    };

    const rowClassRules = {
        "urgent": params => params.data["STATUS"] === "RequiresCallback" && params.data["CALLBACK_PRIORITY"] === "Urgent",
        "notStarted": params => params.data["STATUS"] === "NotStarted",
        "done": params => params.data["STATUS"] === "Done",
    };

    const loadRequests = async () => {
        try {
            const response = await CallCenter.getRequests(CallCenterInstanceName);
            setRequests(response.data);
            loadRowData(response.data);
            setAddedCount(0);
            setDeletedCount(0);
        } catch (error) {
            console.log(error);
        }
    };

    const loadRowData = (requestItems) => {
        console.log("loadRowData called");
        setRowData(requestItems.map(req => ({
            "requestId": req.id,
            "requestDate": Date.parse(req.requestDate),
            "CUSTOMER": Companies[req.requesterCompany]?.name || req.requesterCompany,
            "CUSTOMER_CONTACT": req.requesterUser,
            "ONSIGHT_USER": "",
            "ONSIGHT_USER_EMAIL": req.requesterEmail || "",
            "REQUEST_DATETIME": format(new Date(req.requestDate), "PPp", {locale: ja}),
            "STATUS": req.status,
            "CUSTOMER_COMMENT": req.callback?.comments || req.requesterComments,
            "TRANSCRIPT": req.workspaceCallUrl,
            "TRANSCRIPT_TITLE": req.workspaceCallUrl ? translate("LINK") : req.status == "NotStarted" ? translate("NO_LINK") : translate("PENDING_LINK"),
            "RESPONDER": req.responderName || translate("NONE"),
            "CALLBACK_PRIORITY": req.callback?.priority
        })));
    };

    useEffect(() => {
        loadRequests();

        const websocketUrl = `${CallCenter.responderHubUrl}?extName=${CallCenterInstanceName}`;
        const connection = new HubConnectionBuilder()
            .withUrl(websocketUrl, { accessTokenFactory: async () => await authService.getToken() })
            .withAutomaticReconnect()
            .build();

        connection.start()
            .then(result => {
                connection.on("UpdateRequests", updates => {
                    console.log(`UpdateRequests message received. Updates: ${updates.length}; first update: ${updates[0].id}=${updates[0].status}`);
                    setUpdates(updates);
                });
                connection.on("UpdateRequestCount", counts => {
                    setCountUpdate(counts);
                    if (audioEnabledRef.current) {
                        audio.play();
                    }
                });
            })
            .catch(e => console.log('Connection failed: ', e));
    }, []);

    useEffect(() => {
        const newRequests = [...requests];
        updates.forEach(up => {
            const req = newRequests.find(r => r.id === up.id);
            if (req) {
                req.status = up.status || req.status;
                req.requesterComments = up.requesterComments || req.requesterComments;
                req.responderName = up.responderName || req.responderName;
                req.workspaceCallUrl = up.workspaceCallUrl || req.workspaceCallUrl;
                req.callback = up.callback || req.callback;
            }
        });
        const firstRequest = newRequests.length > 0 ? newRequests[0] : {id:"", status:""};
        console.log(`useEffect newRequests: ${newRequests.length}; first newRequest: ${firstRequest.id}=${firstRequest.status}`);
        setRequests(newRequests);
        loadRowData(newRequests);
    }, [updates]);

    useEffect(() => {
        setAddedCount(countUpdate.added);
        setDeletedCount(countUpdate.deleted);
    }, [countUpdate]);

    useEffect(() => {
        audioEnabledRef.current = audioEnabled;
    }, [audioEnabled]);

    const logout = () => {
        authService.logoutRedirect();
    };

    const toggleAudio = () => {
        setAudioEnabled(!audioEnabled);
    };

    return (
        <>
            <div className="navbar navbar-expand" style={{ margin: "0px 20px" }}>
                {audioEnabled && <FaBell size="32" onClick={toggleAudio} />}
                {!audioEnabled && <FaBellSlash size="36" onClick={toggleAudio} />}
                {addedCount > 0 && <a href="#" style={{fontSize: "x-large", fontWeight: "bold"}} className="ms-3" onClick={loadRequests}>{translate("NEW_REQUESTS_AVAILABLE")}</a>}
                <span className="ms-auto" >{authService.getUserAccount().name}&nbsp;&nbsp;</span>
                <Button variant="warning" onClick={logout}>Sign out</Button>
            </div>
            <div style={{margin: "20px"}}>
                <div className="card mt-2">
                    <div className="card-header font-weight-bold">{translate("CUSTOMER_SUPPORT_REQUESTS")}</div>
                    <div style={{ height: "100%", width: "100%" }} className="">
                        <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                            <div style={{ flexGrow: "1" }}>
                                <div style={{ height: "100%", width: "100%" }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        className="ag-theme-alpine"
                                        animateRows="true"
                                        columnDefs={columnDefs}
                                        defaultColDef={defaultColDef}
                                        rowData={rowData}
                                        rowSelection="multiple"
                                        rowClassRules={rowClassRules}
                                        suppressRowClickSelection="true"
                                        domLayout="autoHeight"
                                        style={{ width: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal centered show={showInitializingModal}>
                <Modal.Header>
                    <Modal.Title>Initializing Call</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Preparing to call {selectedCustomerName}...
                    {showTakingTooLong && <><br /><span>Sorry, this is taking longer than expected.</span></>}
                    <br />
                    <div style={{display: "flex", justifyContent: "center", padding: "40px 0px"}}>
                        <Spinner animation="border" size="xxl" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {showTakingTooLong && <Button className="btn btn-primary" onClick={handleForceStartOCClick}>CallNow</Button>}
                    {showTakingTooLong && <Button className="btn btn-secondary" onClick={handleCancelCallClick}>Cancel</Button>}
                </Modal.Footer>
            </Modal>
        </>
    );
};