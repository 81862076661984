const Companies = {
    829600: {
        name: "*829600",
        emailIndex: 1,
        users: ["社長"],
    },
    34600: {
        name: "*34600",
        emailIndex: 2,
        users: ["吉田", "???"],
    },
    914800: {
        name: "*914800",
        emailIndex: 3,
        users: ["小山内", "新谷"],
    },
    M534100: {
        name: "*M534100",
        emailIndex: 4,
        users: ["社長"],
    },
    257200: {
        name: "*257200",
        emailIndex: 5,
        users: ["梶梅部長", "品証・竹下"],
    },
    796200: {
        name: "*796200",
        emailIndex: 6,
        users: ["生産技術・桜庭"],
    },
    361300: {
        name: "*361300",
        emailIndex: 7,
        users: ["保全・石田"],
    },
    632700: {
        name: "*632700",
        emailIndex: 8,
        users: ["生産技術・北島"],
    },
    M659702: {
        name: "*M659702",
        emailIndex: 9,
        users: ["山中"],
    },
};

export default Companies;